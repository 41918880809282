import React, { useRef, useState } from "react";
import Memorandums from "../../Components/Memorandums/controller";
import MemorandumsLink from "../../Components/MemorandumsLink/controller";
import Scripts from "../../Components/Scripts/H/controller";
import DailySchedule from "../../Components/DailySchedule/controller";
import CameraPositions from "../../Components/CameraPositions/controller";
import CameraFlowcharts from "../../Components/CameraFlowcharts/controller";
import SponsorReads from "../../Components/SponsorReads/H/controller";
import SponsorshipAgenda from "../../Components/SponsorshipAgendas/controller";
import Personnel from "../../Components/Personnel/controller";
import StreamDetails from "../../Components/StreamDetails/controller";
import CameraAssignments from "../../Components/CameraAssignments/controller";
import DotComLowerThirds from "../../Components/DotComLowerThirds/H/controller";
import Timesheets from "../../Components/Timesheets/controller";
import UrgentMessageFiles from "../../Components/UrgentMessageFiles/controller";
import UrgentMessage from "../../Components/UrgentMessage/controller";
import EditToolbar from "../../Components/Toolbar/controller";
import EditTournamentSidebar from "../../Components/Sidebar/controller";
import PlayerNotes from "../../Components/PlayerNotes/controller";
import TechnicalWorkbook from "../../Components/TechnicalWorkbook/controller";
import DailySchedulePGATE from "../../Components/DailySchedulePGATE/controller";

export default function EditTournamentS({ tournament }) {
  const [sidebarToggled, setSidebarToggled] = useState(true);

  const sectionRefs = {
    memorandums: {
      ref: useRef(null),
      label: "Production Memos",
      key: "memorandums",
    },
    timesheets: {
      ref: useRef(null),
      label: "Timesheets",
      key: "timesheets",
    },
    urgent_message: {
      ref: useRef(null),
      label: "Urgent Message",
      key: "urgent_message",
    },
    daily_schedule: {
      ref: useRef(null),
      label: "On Site Daily Schedule",
      key: "daily_schedule",
    },
    daily_schedule_pgate: {
      ref: useRef(null),
      label: "Daily Schedule (STUDIOS)",
      key: "daily_schedule_pgate",
    },
    broadcast_details: {
      ref: useRef(null),
      label: "Broadcast Details",
      key: "broadcast_details",
    },
    player_notes: {
      ref: useRef(null),
      label: "Player Notes",
      key: "player_notes",
    },
    camera_flowcharts: {
      ref: useRef(null),
      label: "Camera Plans",
      key: "camera_flowcharts",
    },
    camera_positions: {
      ref: useRef(null),
      label: "Camera Starting Positions",
      key: "camera_positions",
    },
    camera_assignments: {
      ref: useRef(null),
      label: "Director Documents",
      key: "camera_assignments",
    },
    technical_workbook: {
      ref: useRef(null),
      label: "Additional Documents",
      key: "technical_workbook",
    },
    scripts: {
      ref: useRef(null),
      label: "Scripts",
      key: "scripts",
    },
    tournament_personnel: {
      ref: useRef(null),
      label: "Tournament Personnel",
      key: "tournament_personnel",
    },
    sponsor_agendas: {
      ref: useRef(null),
      label: "Sponsorship Agendas",
      key: "sponsor_agendas",
    },
    sponsor_reads: {
      ref: useRef(null),
      label: "Sponsorship Reads",
      key: "sponsor_reads",
    },
    dotcom_thirds: {
      ref: useRef(null),
      label: "DOTCOM Lower Thirds",
      key: "dotcom_thirds",
    },
  };

  if (!tournament) return null;
  return (
    <>
      <EditToolbar tournament={tournament} />
      <EditTournamentSidebar
        tournament={tournament}
        sectionRefs={sectionRefs}
        sidebarToggle={{
          value: sidebarToggled,
          setter: setSidebarToggled,
        }}
      />
      <div className="edit__tournament__body">
        <Memorandums
          tournamentId={tournament.id}
          memos={tournament?.memos}
          sectionRef={sectionRefs.memorandums.ref}
        />
        <MemorandumsLink
          tournamentId={tournament.id}
          links={tournament?.memos_link}
        />
        <Timesheets
          tournamentId={tournament.id}
          files={tournament?.timesheet}
          sectionRef={sectionRefs.timesheets.ref}
        />
        <UrgentMessage
          tournamentId={tournament.id}
          message={tournament?.urgent_message}
          sectionRef={sectionRefs.urgent_message.ref}
        />
        <UrgentMessageFiles
          tournamentId={tournament.id}
          files={tournament?.urgent_message_file}
          sectionRef={undefined} // by design.
        />
        <DailySchedule
          tournamentId={tournament.id}
          schedule={tournament.daily_schedule}
          sectionRef={sectionRefs.daily_schedule.ref}
        />
        <DailySchedulePGATE
          tournamentId={tournament.id}
          schedule={tournament?.daily_schedule_pgate}
          sectionRef={sectionRefs.daily_schedule_pgate.ref}
        />
        <StreamDetails
          tournamentId={tournament.id}
          details={tournament?.stream_detail}
          sectionRef={sectionRefs.broadcast_details.ref}
          label={"Broadcast Details"}
        />
        <PlayerNotes
          tournamentId={tournament.id}
          playerNotes={tournament?.resources?.talent.player_note}
          sectionRef={sectionRefs.player_notes.ref}
        />
        <CameraFlowcharts
          tournamentId={tournament.id}
          cameraFlowcharts={tournament?.resources?.crew?.cameraPlanFlowcharts}
          sectionRef={sectionRefs.camera_flowcharts.ref}
        />
        <CameraPositions
          tournamentId={tournament.id}
          cameraPositions={tournament?.resources?.crew?.cameraStartingPositions}
          sectionRef={sectionRefs.camera_positions.ref}
        />
        <CameraAssignments
          tournamentId={tournament.id}
          assignments={tournament?.resources?.crew?.cameraAssignment}
          sectionRef={sectionRefs.camera_assignments.ref}
        />
        <TechnicalWorkbook
          tournamentId={tournament.id}
          workbooks={tournament?.resources?.crew?.technical_workbook}
          sectionRef={sectionRefs.technical_workbook.ref}
        />
        <Scripts
          tournamentId={tournament.id}
          scriptFiles={tournament?.resources?.talent.script_file}
          sectionRef={sectionRefs.scripts.ref}
        />
        <Personnel
          tournamentId={tournament.id}
          personnel={tournament.personnel}
          sectionRef={sectionRefs.tournament_personnel.ref}
        />
        <SponsorshipAgenda
          tournamentId={tournament.id}
          sponsorAgendas={tournament?.resources?.crew?.sponsorshipAgendas}
          sectionRef={sectionRefs.sponsor_agendas.ref}
        />
        <SponsorReads
          tournamentId={tournament.id}
          sponsorReads={tournament?.resources?.talent.sponsorship_read_round}
          sectionRef={sectionRefs.sponsor_reads.ref}
        />
        <DotComLowerThirds
          sectionRef={sectionRefs.dotcom_thirds.ref}
          promos={tournament?.resources?.talent?.dotComLowerThirds_round}
          tournamentId={tournament.id}
        />
      </div>
    </>
  );
}
